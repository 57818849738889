body {
  font-family: 'Merriweather', serif;
  color: #484848;
  background-color: #ffffff!important;
}

p {
  line-height: 30px;
}

.container {
  padding: 20px;
  padding-top: 10px;
  position: relative;
}

.nav-bar {
  margin-top: 20px;
  display: flex;
}

.header {
  position: relative;
  color: #ffffff;
  overflow: hidden;
}

.nice-hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.header__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #543e88, #2b2046);
  transform: skewY(-36deg);
  transform-origin: top left;
}

.lead-block {
  padding-top: 40px;
  padding-bottom: 40px;
}

.lead-block h1 {
  font-size: 2.7em;
}

.lead-block h1 span {
  display: none;
}

.lead-block p {
  font-size: 1.2em;
}

.marketing-section h3 {
  font-weight: 400;
}

.four-factors {
  text-align: center;
}

.four-factors-item {
  color: #ea6bc8;
  fill: #ea6bc8;
  text-align: center;
}

.left-right-block h3 {
  color: #ea6bc8;
  font-weight: 700;
  margin-bottom: 6px;
  margin-top: 0;
}

.left-right-block p {
  margin-bottom: 30px;
}

.history-analyzer img, .dashboard img, .avatar-shadow {
  box-shadow: 10px 10px 40px 0 rgba(43, 32, 70,.1);
}

.section-gradient {
  /* background-image: linear-gradient(90deg, #f7d7bf, #ffffff); */
  background-image: linear-gradient(90deg, #543e88, #2b2046);
}

.section-gradient__vertical {
  /* background-image: linear-gradient(90deg, #f7d7bf, #ffffff); */
  background-image: linear-gradient(0deg, rgba(43, 32, 70,.1), #ffffff);
}

.react-table-center {
  text-align: center;
}

footer {
  padding: 20px;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
}

.pricing-plan {
  padding: 20px;
  text-align: center;
}

.pricing-plan.selected {
  background-color: rgba(84, 62, 136, 0.1);
}

.price-bar {
  color: #fff;
  background-color: #543e88;
  padding: 10px;
  margin-left: -20px;
  margin-right: -20px;
  font-size: 18px;
}

.featured-plan {
  color: #fff;
  background-color: #543e88;
  margin: -20px;
  padding: 20px 0;
}

.pricing ul {
  list-style: none;
  margin: 25px 0;
  padding: 0;
}
.pricing li {
  line-height: 36px;
}

.data-points {
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.floaty-box {
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.box-shadow {
  box-shadow: 10px 10px 40px 0 rgba(43, 32, 70,.1);
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: solid 1px rgba(0,0,0,0.2);
}

.ReactTable .rt-th, .ReactTable .rt-td {
  font-family: sans-serif;
  font-size: 13px;
}

.ReactTable .rt-tfoot {
  box-shadow: none !important;
  border-top: solid 1px rgba(0,0,0,0.2);
}

.rc-slider-handle {
  border-color: rgb(234, 107, 200) !important;
}
.rc-slider-tooltip {
  display: block !important;
}

.desktop-nav-sections, .desktop-nav-user {
  display: none;
}
.mobile-nav {
  display: block;
}

.desktop-nav-sections ul {
  display: inline;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.desktop-nav-sections li {
  list-style: none;
  display: inline;
  margin-right: 30px;
}

.desktop-nav-sections a {
  color: #ffffff;
  text-decoration: none;
}

.desktop-nav-sections a:hover {
  border-bottom: 2px #ea6bc8 solid;
  margin: 0;
  padding: 0;
}

/* Desktop styles. */
@media (min-width: 800px) {
  .hide-desktop {
    display: none;
  }

  .desktop-nav-sections, .desktop-nav-user {
    display: inline;
  }
  .mobile-nav {
    display: none;
  }
  a.nav-active {
    border-bottom: 2px #ea6bc8 solid;
  }

  .main-wrapper {
    margin: 65px auto 0;
  }

  .container {
    margin-left: 60px;
    margin-right: 60px;
  }

  .marketing-section {
    margin-left: 60px;
    margin-right: 60px;
  }

  .nav-bar {
    padding-left: 60px;
    padding-right: 60px;
  }

  .header {
    min-height: 700px;
    margin-bottom: -80px;
  }

  .header__bg {
    transform: skewY(-16deg);
  }

  .four-factors {
    text-align: left;
  }

  .lead-block h1 span {
    display: inline;
  }
}
